import Link from "next/link";
import Separator from "../Common/Separator";

const CopyRight = ({
  brand = {
    _id: "66db0e6b9986268c3f6e50c2",
    companyName: "Thewebvale",
    phone: "+91 8770183178",
    logo: "https://ik.imagekit.io/Yash/Logo/Thewebvale-logo-black_415Mlrb_p.png?updatedAt=1726246911636",
  },
}) => {
  return (
    <>
      <Separator />
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-start">
                Copyright © 2024{" "}
                <Link href="https://thewebvale.com">
                  {brand.displayName || "Thewebvale Studios"}.
                </Link>{" "}
                All Rights Reserved
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <Link href="#">Terms of service</Link>
                </li>
                <li>
                  <Link href="/pages/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link href="#">Subscription</Link>
                </li>
                <li>
                  <Link href="#">Login & Register</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
